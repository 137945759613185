import { Search } from "./search";
import { Upload } from "./upload";

export class QnaDTO {

    id: number;

    name: string;

    email: string;

    category: string = "all";

    status: string;

    phone: string;

    title: string;

    content: string;

    answer: string;

    answerId: string;

    img1: Upload = new Upload();

    img2: Upload = new Upload();

    img3: Upload = new Upload();

    img4: Upload = new Upload();

    img5: Upload = new Upload();

    regdate: Date;

    updateDate: Date;

}


export class QnaSearchDTO extends Search {

    email: string;

}