import React from 'react';
import Editor from '../_common/editor/editor';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko'; // 한국어 지역 설정

const QnaUpdateV = ({ t, params, setParams, handleChange, onFileChange, isUpdate, submit, remove }) => (
    <>

        <div className="content-header">
            <h1>
                <font>Q&A</font>
            </h1>
        </div>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{isUpdate ? "수정" : "등록"}</h3>

                    <div className="card-tools">
                    </div>

                </div>
                {params.isLoaded && params.error == null && (
                    <>
                        <div className="g_update p-0 mt_30">
                            <table className="g_table table_type1">
                                <colgroup>
                                    <col width="220" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th><label>제목</label></th>
                                        <td><div className="inner"><input disabled className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.title", setParams, params)} value={params.item.title} /></div></td>
                                    </tr>

                                    <tr>
                                        <th><label>이메일</label></th>
                                        <td><div className="inner"><input disabled className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.email", setParams, params)} value={params.item.email} /></div></td>
                                    </tr>

                                    <tr>
                                        <th><label>타입</label></th>
                                        <td>
                                            <div className="inner">
                                                <span className="g_select">
                                                    <select disabled className="form-control" onChange={e => handleChange(e, "item.category", setParams, params)} value={params.item.category}>
                                                        <option value="hapjeong_darak">합정다락</option>
                                                        <option value="darakparty">다락파티</option>
                                                        <option value="darak_chaegjang">다락책장</option>
                                                        <option value="all">기타</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>첨부이미지</label></th>
                                        <td>
                                            <div className="inner">
                                                {/* <input type="file" accept="image/*" onChange={onFileChange} /> */}
                                                {params.item?.img1?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.img1._preview} />
                                                        </div>
                                                    </>
                                                )}
                                                {params.item?.img2?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.img2._preview} />
                                                        </div>
                                                    </>
                                                )}
                                                {params.item?.img3?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.img3._preview} />
                                                        </div>
                                                    </>
                                                )}
                                                {params.item?.img4?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.img4._preview} />
                                                        </div>
                                                    </>
                                                )}
                                                {params.item?.img5?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.img5._preview} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>이름</label></th>
                                        <td><div className="inner"><input disabled className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.name", setParams, params)} value={params.item.name} /></div></td>
                                    </tr>

                                    <tr>
                                        <th><label>휴대폰번호</label></th>
                                        <td><div className="inner"><input disabled className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.phone", setParams, params)} value={params.item.phone} /></div></td>
                                    </tr>

                                    <tr>

                                        <th><label>문의내용</label></th>
                                        <td>
                                            <div dangerouslySetInnerHTML={{ __html: params.item.content }}>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>

                                        <th><label>답변</label></th>
                                        <td>
                                            <div>
                                                <Editor
                                                    value={params.item.answer}
                                                ></Editor>
                                            </div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer">
                            <a className="btn btn-success float-left" onClick={() => { history.back() }}><i className='fas fa-long-arrow-left mr_10'></i>Back</a>

                            <button type="button" onClick={submit} className="btn btn-primary float-right">Save</button>
                            {isUpdate && (
                                <button type="button" onClick={remove} className="btn btn-danger float-right mr_10">Remove</button>
                            )}
                        </div>
                    </>
                )
                }

            </div>

        </div>

    </>
);

export default QnaUpdateV;