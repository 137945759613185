import http from 'core/http.service';
import BaseService from "services/BaseService";
import { Page } from 'models/page';
import { QnaDTO, QnaSearchDTO } from 'models/qna';

class QnaService extends BaseService {

    public getList(search: QnaSearchDTO): Promise<Page<QnaDTO>> {
        return super.request(http.get("/qna", search));
    }

    public get(id: number): Promise<QnaDTO> {
        return super.request(http.get(`/qna/${id}`));
    }

    public save(dto: QnaDTO): Promise<void> {
        return super.request(http.post("/qna", dto));
    }

    public remove(id: number): Promise<void> {
        return super.request(http.delete(`/qna/${id}`));
    }

}

let qnaService;
export default qnaService = new QnaService();