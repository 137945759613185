import { Search } from "./search";
import { Upload } from "./upload";

export class MainDTO {

    subContents: Array<MainSubContentDTO> = new Array<MainSubContentDTO>();

    reserveContents: Array<MainReserveContentDTO> = new Array<MainReserveContentDTO>();

}

export class MainSubContentDTO {

    sort: number;

    thumb: string;

    img: Upload;

    title: string;

    subTitle: string;

    content: string;

}

export class MainReserveContentDTO {

    sort: number;

    thumb: string;

    img: Upload;

    link: string;

    intro: string;

    btn: string;

}
