import MainV from './Main.v';
import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import pageUtil from "utils/Page.util";
import moment from 'moment';
import { ModalDTO } from 'models/common';
import mainService from 'services/main.service';
import { MainDTO, MainReserveContentDTO, MainSubContentDTO } from 'models/main';
import { Upload } from 'models/upload';
import pageStateService from 'core/PageState.service';

class Params {
    error: string = null;
    isLoaded: boolean = false;

    item: MainDTO = new MainDTO();

};

function Main(props: any) {

    const navigate = useNavigate();
    const [params, setParams] = useState(new Params());
    const mounted = useRef(false);

    //onMount
    useEffect(() => {

        console.log("onMount");
        console.log("LOAD FROM API");
        mounted.current = true;

        console.log("mounted");
        getData();

    }, []);

    async function getData(): Promise<any> {

        await mainService.get().then(p => {

            console.log("getData", p);

            p.subContents.forEach(content => {
                if (content.img && content.img.name) content.img._preview = content.img.name;
            });

            p.reserveContents.forEach(content => {
                if (content.img && content.img.name) content.img._preview = content.img.name;
            });

            setParams((params) => ({
                ...params,
                item: p,
                isLoaded: true,
            }));

        }).catch(e => {

            setParams((params) => ({
                ...params,
                isLoaded: true,
                error: e
            }));

            pageUtil.handleApiError(e);

        });

    }

    function goPage(path: string) {
        navigate(path);
    }

    function addContent(type: string): void {

        const item = pageUtil.deepCopy(params.item) as MainDTO;
        if (type == "sub") {
            item.subContents.push(new MainSubContentDTO());
        } else if (type == "reservation") {
            item.reserveContents.push(new MainReserveContentDTO());
        }

        setParams((params) => ({
            ...params,
            item: item,
        }));

    }

    function removeContent(type: string, index: number): void {

        const item = pageUtil.deepCopy(params.item) as MainDTO;

        if (type == "sub") {
            item.subContents.splice(index, 1);
        } else if (type == "reservation") {
            item.reserveContents.splice(index, 1);
        }

        setParams((params) => ({
            ...params,
            item: item,
        }));

    }

    function onChangeContentSort(type: string, e, index: number): void {

        const item = pageUtil.deepCopy(params.item) as MainDTO;

        if (type == "sub") {
            item.subContents[index].sort = e.target.value;

            item.subContents.sort((a: MainSubContentDTO, b: MainSubContentDTO): number => {
                return a.sort - b.sort;
            });

        } else if (type == "reservation") {
            item.reserveContents[index].sort = e.target.value;

            item.reserveContents.sort((a: MainReserveContentDTO, b: MainReserveContentDTO): number => {
                return a.sort - b.sort;
            });
        }

        setParams((params) => ({
            ...params,
            item: item,
        }));

    }

    function onFileChange(type: string, files, index: number): void {

        // const files = e.target.files;

        if (files && files.length > 0) {
            // For Preview
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                const item = pageUtil.deepCopy(params.item) as MainDTO;
                let img = new Upload();
                img._preview = reader.result;
                img.name = file.name;
                img.size = file.size;
                img.value = reader.result;
                if (type == "sub") {
                    item.subContents[index].img = img;
                } else if (type == "reservation") {
                    item.reserveContents[index].img = img;
                }

                setParams((prev) => ({
                    ...prev,
                    item: item
                }));

            };

        }

    }

    async function submit() {

        let dto = pageUtil.deepCopy(params.item);

        console.log("submit", params.item, dto);

        await mainService.save(dto).then((res) => {

            console.log("res", res);
            alert("완료되었습니다.");

            pageStateService.clearPageState();
            navigate("/main", { replace: true });


        }).catch(e => {

            pageUtil.handleApiError(e);

        })

    }


    const args = {
        params: params,
        setParams: setParams,
        mounted: mounted,
        goPage: goPage,
        submit: submit,
        addContent: addContent,
        removeContent: removeContent,
        onChangeContentSort: onChangeContentSort,
        onFileChange: onFileChange,
        handleChange: pageUtil.handleChange,
    }

    return (
        <MainV {...args}></MainV>
    );

}

export default withRouter(Main, [mainService]);