import http from 'core/http.service';
import BaseService from "services/BaseService";
import { Page } from 'models/page';
import { BoardDTO, BoardSearchDTO } from 'models/board';

class BoardService extends BaseService {

    public getList(search: BoardSearchDTO): Promise<Page<BoardDTO>> {
        return super.request(http.get("/board", search));
    }

    public get(id: number): Promise<BoardDTO> {
        return super.request(http.get(`/board/${id}`));
    }

    public save(dto: BoardDTO): Promise<void> {
        return super.request(http.post("/board", dto));
    }

    public remove(id: number): Promise<void> {
        return super.request(http.delete(`/board/${id}`));
    }

}

let boardService;
export default boardService = new BoardService();