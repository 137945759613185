import pageStateService from 'core/PageState.service';
import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import pageUtil from "utils/Page.util";
import QnaUpdateV from "./Qna.update.v";
import authService from 'services/auth.service';
import { QnaDTO } from 'models/qna';
import qnaService from 'services/qna.service';

class Params {

    error: string = null;
    isLoaded: boolean = false;

    item: QnaDTO = new QnaDTO();

};

function QnaUpdate(props: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState(new Params());
    const { state } = location;
    const id = state?.id;

    const isUpdate: boolean = state?.isUpdate;

    // const mounted = useRef(false);

    //onMount
    useEffect(() => {

        console.log("onMount");

        getData();

        return () => {
        }

    }, []);

    async function getData() {

        if (id) {

            try {
                const res = await qnaService.get(id);
                const curParams = pageUtil.deepCopy(params);

                console.log("item", res);

                const item = Object.assign(new QnaDTO(), res);
                if (item.img1 && item.img1.name) item.img1._preview = item.img1.name;
                if (item.img2 && item.img2.name) item.img2._preview = item.img2.name;
                if (item.img3 && item.img3.name) item.img3._preview = item.img3.name;
                if (item.img4 && item.img4.name) item.img4._preview = item.img4.name;
                if (item.img5 && item.img5.name) item.img5._preview = item.img5.name;

                curParams.item = item;

                setParams((prev) => ({
                    ...prev,
                    error: null,
                    isLoaded: true,
                    item: curParams.item
                }));

            } catch (e) {

                console.log("exception ", e);
                setParams((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: e
                }));

                pageUtil.handleApiError(e);
            }


        } else {

            const item = new QnaDTO();
            setParams((params) => ({
                ...params,
                isLoaded: true,
                item: item
            }));
        }

    }

    async function remove() {

        if (confirm("삭제하시겠습니까?")) {

            await qnaService.remove(id).then((res) => {

                alert("완료되었습니다.");

                pageStateService.clearPageState();
                navigate("/qna", { replace: true });


            }).catch(e => {

                pageUtil.handleApiError(e);

            })
        }
    }

    async function submit() {

        let dto = pageUtil.deepCopy(params.item) as QnaDTO;

        window["oEditors"].getById["content"].exec("UPDATE_CONTENTS_FIELD", []);
        dto.answer = document.querySelector("#content")["value"];

        dto.answerId = authService.getUserId();

        console.log("submit", params.item, dto, isUpdate);

        await qnaService.save(dto).then((res) => {

            console.log("res", res);
            alert("완료되었습니다.");

            pageStateService.clearPageState();
            navigate("/qna", { replace: true });


        }).catch(e => {

            pageUtil.handleApiError(e);

        })

    }

    function onFileChange(e, obj: any): void {

        const files = e.target.files;

        if (files && files.length > 0) {
            // For Preview
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                const item = pageUtil.deepCopy(params.item);
                item.thumb._preview = reader.result;
                item.thumb.name = file.name;
                item.thumb.size = file.size;
                item.thumb.value = reader.result;

                setParams((prev) => ({
                    ...prev,
                    item: item
                }));


            };
        }

    }

    const args = {
        params: params,
        setParams: setParams,
        handleChange: pageUtil.handleChange,
        onFileChange: onFileChange,
        isUpdate: isUpdate,
        submit: submit,
        remove: remove,
        // handleOrganization: handleOrganization,
    }


    // return <VAC data={props} />;

    return <QnaUpdateV {...args} />;

}


export default withRouter(QnaUpdate, [qnaService]);