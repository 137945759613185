import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from "core/route.protected";
import Loading from "Loading";
import QnaList from './Qna.list';
import QnaUpdate from './Qna.update';

function Qna() {
  return (
    <>

      <Routes>
        <Route path='/' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><QnaList /></React.Suspense>
          </ProtectedRoute>
        }></Route>

        <Route path='/update' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><QnaUpdate /></React.Suspense>
          </ProtectedRoute>
        }></Route>

      </Routes>
    </>

  );
};

export default Qna;
